import React, {Dispatch, SetStateAction} from 'react';
import Checkbox from "./Checkbox";
import {useTranslation} from "react-i18next";

type Props = {
    gender: string
    setGender: Dispatch<SetStateAction<string>>
}

const GenderSelector = ({gender, setGender}: Props) => {
    const { t } = useTranslation();

    return (
        <div className="gender-selector-container">
            <div>{t('Gender')}:</div>
            <div>
                <Checkbox label={t('Male')} id="MALE" gender={gender} setGender={setGender}/>
                <Checkbox label={t('Female')} id="FEMALE" gender={gender} setGender={setGender}/>
            </div>
        </div>
    );
};

export default GenderSelector;
