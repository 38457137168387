import React, {Dispatch, SetStateAction} from 'react';
import './Checkbox.css'

type Props = {
    id: string,
    label: string,
    gender: string
    setGender: Dispatch<SetStateAction<string>>
}

const Checkbox = ({id, label, gender, setGender}: Props) => {
    return (
        <div className="checkbox">
            <input
                type="radio"
                name={"gender"}
                id={id}
                checked={id === gender}
                onChange={(event) => setGender(event.target.id)}
                tabIndex={gender === 'MALE' ? 2 : 3}
                autoComplete="sex"
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default Checkbox;
