import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

console.log(process.env.LANGUAGE)
console.log(process.env)

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            si: {
                translation: {
                    "Header": "Kdo je cestni heroj?",
                    "DescriptionPart1": "Cestni heroj se zavzema za strpnost do kolesarjev, zagovarja medsebojno spoštovanje vseh udeležencev v prometu ter želi širiti harmonijo in pozitiven odnos. Razume vse uporabnike cest, a se v prvi vrsti bori proti dejanjem, ki ogrožajo zdravje in življenje kolesarjev. Cestni heroj je vedno pripravljen pomagati.",
                    "DescriptionPart2": "Njegova strast je kolesarjenje. Z drugimi kolesarji deli lokacije, informacije ter vse, kar je povezano s kolesarstvom. Glavni cilj teh njegovih dejanj je, da bi lahko kar se da varno v kolesarjenju uživalo čim več ljudi.",
                    "DescriptionPart3": "Si tudi ti CESTNI HEROJ?",
                    "BecomeARoadHeroButton": "POSTANI CESTNI HEROJ!",
                    "OurAmbassadorsTitle": "Naši ambasadorji",
                    "ImagePlaceholder": "Slika",
                    "Close": "Zapri",
                    "IWantToBecomeARoadHero": "Želim postati cestni heroj",
                    "NameAndSurname": "Ime in priimek",
                    "Gender": "Spol",
                    "Male": "moški",
                    "Female": "ženski",
                    "Age": "Starost",
                    "Address": "Ulica",
                    "Postal": "Poštna št.",
                    "City": "Kraj",
                    "Country": "Država",
                    "Email": "Elektronski naslov",
                    "Send": "POŠLJI",
                    "GDPR": "* Vaši podatki se obdelujejo v skladu z aktualno zakonodajo GDPR.",
                    "FollowUs": "Spremljajte nas:",
                    "EmailInfoAlert": "Na vpisan elektronski naslov boste prejeli potrditev.",
                    "SuccessfulSubscribeInfo": "Hvala za prijavo!",
                    "VE-0005": "Heroj s to e-pošto že obstaja!",
                    "BackToForm": "Nazaj na obrazec",
                    "Error": "Prišlo je do napake",
                }
            },
            en: {
                translation: {
                    "Header": "Who is a road hero?",
                    "DescriptionPart1": "The ROAD CYCLING HEROES project champions tolerance towards cyclists, advocates for mutual respect of all road users, and aims to spread harmony and positivity. Every ROAD CYCLING HERO understands all road users but is the first to fight actions that put the life and health of cyclists at risk.",
                    "DescriptionPart2": "A ROAD CYCLING HERO is always ready to help. ",
                    "DescriptionPart3": "A ROAD CYCLING HERO’s passion is cycling. He shares locations, information and all things about cycling with other cyclists to make it a safe and enjoyable activity for everyone.",
                    "BecomeARoadHeroButton": "BECOME A ROAD HERO!",
                    "OurAmbassadorsTitle": "Our ambassadors",
                    "ImagePlaceholder": "Image",
                    "Close": "Close",
                    "IWantToBecomeARoadHero": "I want to become a road hero",
                    "NameAndSurname": "Name and surname",
                    "Gender": "Gender",
                    "Male": "male",
                    "Female": "female",
                    "Age": "Age",
                    "Address": "Address",
                    "Postal": "Postal code",
                    "City": "City",
                    "Country": "Country",
                    "Email": "Email",
                    "Send": "SEND",
                    "GDPR": "* Your data is processed in accordance with the current GDPR legislation.",
                    "FollowUs": "Follow us:",
                    "EmailInfoAlert": "A confirmation email has been sent to you.",
                    "SuccessfulSubscribeInfo": "Thank you for your support!",
                    "VE-0005": "Hero with the provided email already exists",
                    "BackToForm": "Back to form",
                    "Error": "An error occurred",
                }
            }
        },
        lng: process.env.REACT_APP_LANGUAGE,
        fallbackLng: process.env.REACT_APP_LANGUAGE,
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;