import React from 'react';
import './FormSentDisplay.css'
import Tick from "./Tick";
import {useTranslation} from "react-i18next";

type Props = {
    shouldShow: boolean,
    isLoading: boolean,
    error?: string,
    backToForm?: () => void,
}

const FormSentDisplay = ({shouldShow, isLoading, error, backToForm}: Props) => {
    const { t } = useTranslation();

    return shouldShow ? (
        <div className="form-sent-container">
            <div className="form-sent-message-container">
                <div className="form-sent-tick">
                    {error ?
                        <div style={{display:"flex", alignItems: "center", justifyContent: "center", fontSize: "16px", flexDirection: "column"}}>
                            <div style={{fontWeight: "bold"}}>{t("Error")}</div>
                            <div>{t(error)}</div>
                        </div>
                        :
                        <Tick isLoading={isLoading}/>}
                </div>
                <div className={`form-sent-title ${isLoading ? "hidden" : ""}`}>
                    {!error && t('SuccessfulSubscribeInfo')}
                </div>
                <div className={`form-sent-subtitle ${isLoading ? "hidden" : ""}`}>
                    {error ?
                        <div className={"button-form"} onClick={backToForm} style={{width: "180px"}}>
                            <span className="button-form-text">{t('BackToForm')}</span>
                        </div>
                        :
                        t('EmailInfoAlert')}
                </div>
            </div>
        </div>
    ) : null;
};

export default FormSentDisplay;
