import React from 'react';
import {useTranslation} from "react-i18next";

type Ambassador = {
    name: string,
    surname: string,
    url: string,
    photoUrl: string
}

type Props = {
    person: Ambassador
}

const Ambassador = ({person}: Props) => {

    const { t } = useTranslation();

    return (
        <a className="ambassador-container" href={person.url} target="_blank" rel="noopener noreferrer">
            <div className="ambassador-image-placeholder"/>
            <img src={person.photoUrl} alt={t('ImagePlaceholder')} className="ambassador-image"/>
            <div className="ambassador-name">{person.name}</div>
            <div className="ambassador-name">{person.surname}</div>
        </a>
    );
};

export default Ambassador;
