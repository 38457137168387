import React, {useRef} from 'react';
import Slider from "react-slick";
import Ambassador from "./Ambassador";
import {useElementSize} from "../hooks/use-element-size";
import Arrow from "./Arrow";
import {useTranslation} from "react-i18next";

const Ambassadors = () => {

    const { t } = useTranslation();

    const { ref, size } = useElementSize(null);
    const sliderRef = useRef(null);

    // @ts-ignore
    let ambassadors = window.ambassadors;
    ambassadors = ambassadors && ambassadors.filter((a: Ambassador) => a.name && a.surname && a.photoUrl && a.url);

    const getSettings = () => {
        const personContainerSize = 85;
        const padding = 44;
        let sliderSize = Math.round((size.width - padding) / personContainerSize);

        if(sliderSize > ambassadors.length)
            sliderSize = ambassadors.length;

        return {
            infinite: false,
            speed: 500,
            slidesToShow: sliderSize,
            slidesToScroll: sliderSize,
            nextArrow: <Arrow isPrev={false} sliderSize={sliderSize} length={ambassadors.length}/>,
            prevArrow: <Arrow isPrev={true} />,
            rows: 1,
        };
    };

    return (
        <div className="slider-container" ref={ref}>
            <div className="slider-title">
                {t('OurAmbassadorsTitle')}
            </div>
            {size && size.width &&
                <Slider {...getSettings()} className="slider" ref={sliderRef}>
                    {ambassadors.map((ambassador: Ambassador, index: number) =>
                        <Ambassador key={index} person={ambassador}/>
                    )}
                </Slider>
            }
        </div>
    );
};

export default Ambassadors;
