import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Ambassadors from "./Ambassadors/Ambassadors";
import logo from './logo.svg';
import './App.css';
import Form from "./Form/Form";
import Footer from "./Footer/Footer";
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_UA);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {

    const { t } = useTranslation();

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <div className="bg">
            <div className="container">
                <img className="logo" src={logo} alt="Logo"/>
                <div className="content-container">
                    <div className="mobile-placeholder"/>
                    <div className="text-container">
                        <div className="title">{t('Header')}</div>
                        <div className="text">
                            {t('DescriptionPart1')}
                            <br/>
                            <br/>
                            {t('DescriptionPart2')}
                            <br/>
                            {t('DescriptionPart3')}
                        </div>
                    </div>
                    <div className="button" onClick={() => setIsPopupOpen(true)}>
                        <span className="button-text">
                            {t('BecomeARoadHeroButton')}
                        </span>
                    </div>
                    <Ambassadors/>
                </div>
            </div>
            <Footer/>
            <Form isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}/>
        </div>
    );
};

export default App;
