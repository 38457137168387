import React from 'react';
import arrow from './arrow.png'

type Props = {
    onClick?: () => void,
    isPrev: boolean,
    currentSlide?: number,
    sliderSize?: number,
    length?: number,
}

const Arrow = ({isPrev, onClick, currentSlide, sliderSize, length}: Props) => {

    return (
        <div className={`slider-arrow ${isPrev ? "rotate" : ""} ${(currentSlide === 0 && isPrev) || (!isPrev && (length - sliderSize <= currentSlide))  ? "opacity" : ""} `} onClick={onClick}>
            <img src={arrow} alt="Arrow"/>
        </div>
    );
};

export default Arrow;
