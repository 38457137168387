import React, {RefObject, useEffect, useRef, useState} from 'react';
// @ts-ignore
import Input from "./Input";
import FormSentDisplay from "./FormSentDisplay";
import GenderSelector from "./GenderSelector";
import AgeSelector from "./AgeSelector";
import close from './close.png'
import axios from 'axios'
import './Form.css';
import {useTranslation} from "react-i18next";
import CountrySelector from "./CountrySelector";

type Props = {
    isOpen: boolean,
    onClose: () => void
}

const Form = ({isOpen, onClose}: Props) => {

    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("empty");
    const [address, setAddress] = useState("");
    const [postal, setPostal] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [emailNotValid, setEmailNotValid] = useState(false);
    const [postalNotValid, setPostalNotValid] = useState(false);
    const [formSentDisplay, setFormSentDisplay] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateEmail = (event: any) => {
        const value = event.target.value;
        if(value) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValid = re.test(String(value).toLowerCase());
            if(!isValid) {
                setEmailNotValid(true)
            } else {
                setEmailNotValid(false)
            }
        } else {
            setEmailNotValid(false)
        }
    };

    const validatePostal = (event: any) => {
        const value = event.target.value;
        if(value) {
            const re = /^[0-9]{4}$/;
            const isValid = re.test(String(value).toLowerCase());
            if(!isValid) {
                setPostalNotValid(true)
            } else {
                setPostalNotValid(false)
            }
        } else {
            setPostalNotValid(false)
        }
    };

    const postForm = () => {
        setIsLoading(true);
        setFormSentDisplay(true);
        axios.post("https://int.radio3dot0.com:8443/rh/road-heroes/heroes", {
            firstAndLastName: name,
            gender: gender,
            age: age,
            email: email,
            street: address,
            postCode: postal,
            post: city,
        }).then(() => {
            setIsLoading(false);
            setName("");
            setGender("");
            setAge("empty");
            setAddress("");
            setPostal("");
            setCity("");
            setCountry("");
            setEmail("");
        }, (error) => {
            setIsLoading(false);
            setError(error.response && error.response.data && error.response.data.errors && error.response.data.errors[0].code)
        });
    };

    const closeForm = () => {
        onClose();
        setFormSentDisplay(false);
    };

    const backToForm = () => {
        setFormSentDisplay(false)
        setError(null)
    }

    const shouldBeDisabled = () => {
        if(!name || !age || !gender || !address || !postal || !city || !email)
            return true;
        if(emailNotValid)
            return true
        return postalNotValid;
    };

    const ref = useRef<HTMLDivElement>(null);
    useOutsideAlerter(ref, closeForm);

    return (
        <div className={isOpen ? "form-container-backdrop" : "form-container-backdrop hidden"}>
            <div className={isOpen ? "form-layout-container" : "form-layout-container hidden"} >
                <div className={`form-center ${isOpen ? "width-100" : "width-0"}`}>
                    <img src={close} alt={t('Close')} className="close-form-icon" onClick={closeForm}/>
                    <div className="form-container" ref={ref}>
                    <FormSentDisplay shouldShow={formSentDisplay} isLoading={isLoading} error={error} backToForm={backToForm}/>
                        <div className="form-title">{t('IWantToBecomeARoadHero')}</div>
                        <div className="form-group">
                            <Input placeholder={t('NameAndSurname')} value={name} onChange={setName} tabIndex={1} name="name"/>
                            <GenderSelector
                                gender={gender}
                                setGender={setGender}
                            />
                            <AgeSelector
                                age={age}
                                setAge={setAge}
                            />

                            <Input placeholder={t('Address')} value={address} onChange={setAddress} tabIndex={5} name="address"/>
                            <div className="city-container">
                                <Input
                                    type="number"
                                    placeholder={t('Postal')}
                                    value={postal}
                                    onChange={setPostal}
                                    onBlur={validatePostal}
                                    className={`postal ${postalNotValid ? "input-error" : ""}`}
                                    tabIndex={6}
                                    name="postal"
                                />
                                <Input
                                    placeholder={t('City')}
                                    className="city"
                                    value={city}
                                    onChange={setCity}
                                    tabIndex={7}
                                    name="city"
                                />
                            </div>
                            {process.env.REACT_APP_LANGUAGE === 'en' &&
                                <CountrySelector
                                    country={country}
                                    setCountry={setCountry}
                                />
                            }
                            <Input
                                type="email"
                                placeholder={t('Email')}
                                value={email}
                                onChange={setEmail}
                                onBlur={validateEmail}
                                className={emailNotValid ? "input-error" : ""}
                                tabIndex={8}
                                name="email"
                            />
                            {shouldBeDisabled() ?
                                <div className={"button-form disabled-button"} >
                                    <span className="button-form-text">{t('Send')}</span>
                                </div>
                                :
                                <div className={"button-form"} onClick={postForm} >
                                    <span className="button-form-text">{t('Send')}</span>
                                </div>
                            }

                        </div>
                        <div className="disclaimer">
                            {t('GDPR')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) ;
};

const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, closeForm: () => void) => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            closeForm();
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}


export default Form;
