import React, {Dispatch, SetStateAction} from 'react';

type Props = {
    placeholder?: string,
    value?: string,
    className?: string
    type?: string,
    onChange: Dispatch<SetStateAction<string>>
    onBlur?: (event: any) => void,
    tabIndex: number,
    name?: string
}

const Input = ({tabIndex, value, placeholder, className, type, onChange, onBlur, name}: Props) => {
    return (
        <input
            onChange={(event) => onChange(event.target.value)}
            type={type ? type : "text"}
            placeholder={placeholder}
            value={value}
            className={`input-container ${className}`}
            onBlur={onBlur}
            tabIndex={tabIndex}
            name={name}
            id={name}
            autoComplete={name}
        />
    );
};

export default Input;
