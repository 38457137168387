import React, {Dispatch, SetStateAction} from 'react';
import './AgeSelector.css'
import {useTranslation} from "react-i18next";

type Props = {
    age: string
    setAge: Dispatch<SetStateAction<string>>
}

const AgeSelector = ({age, setAge}: Props) => {

    const { t } = useTranslation();

    const ageNumber = Array.from(Array(83).keys());

    return (
        <div className="age-selector-container custom-select" >
            <label htmlFor="age" className="label">{t('Age')}:</label>
            <select
                value={age}
                onChange={(event) => setAge(event.target.value)}
                tabIndex={4}
                id="age"
                name="age"
                autoComplete="age"
            >
                <option value="empty" disabled style={{display:"none"}}> </option>
                {ageNumber.map((number, index) =>
                <option key={index} value={index+18}>{number+18}</option>
                )}
            </select>
        </div>
    );
};

export default AgeSelector;
