import {useCallback, useEffect, useRef, useState} from 'react';
import throttle from 'lodash/throttle';

// This will cause re-render each time window is resized.

// If you use Suspense (eg. suspense + useSuspenseStore) to display image inside component that is using this hook
// this image will flash each time window is resized. Suspense doesn't track components inside it
// so two images before and after render are 2 different images and CSS class applied anew after render.
export const useElementSize = (fallback: Partial<ClientRect> | null) => {
    const [size, setSize] = useState<ClientRect>(fallback as ClientRect);
    const el = useRef<HTMLElement | null>(null);
    const ref = useCallback((element: HTMLElement | null) => {
        el.current = element;
        //We need to wait for next tick for image to appear in DOM
        if (element)
            setTimeout(() => {
                setSize(element.getBoundingClientRect());
            })
    }, []);
    useEffect(() => {
        const listener = throttle(
            () => ref(el.current),
            500,
            { leading: false }
        );
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [ref]);
    return {
        el,
        size,
        ref,
    };
};
